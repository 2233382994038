<template lang="pug">
settings-panel(v-loading="isFetching")
  template(#header) Компания
  template(#headerActions)
    icon-button(
      v-if="isTmManager"
      :size="16"
      :icon="UiIconNames.Icon_Edit"
      tooltip-title="Редактировать данные компании"
      @click="goToPage"
    )
  .info-table
    .company-info
      .card-item
        .label Название
        .value.row
          ui-icon.margin(v-if="company.title" :icon="UiIconNames.Icon_Home" :size="14")
          span {{ company.title ? company.title : '-' }}
      .card-item
        .label Полное название
        .value {{ company.fullTitle ? company.fullTitle : '-' }}
    .company-info
      .card-item
        .label ИНН
        .value {{ company.inn ? company.inn : '-' }}
      .card-item
        .label КПП
        .value {{ company.kpp ? company.kpp : '-' }}
      .card-item
        .label ОГРН
        .value {{ company.ogrn ? company.ogrn : '-' }}
      .card-item
        .label Юридический адрес
        .value {{ company.legalAddress ? company.legalAddress : '-' }}
      .card-item
        .label Фактический адрес
        .value {{ company.factAddress ? company.factAddress : '-' }}
      .card-item
        .label Почтовый адрес
        .value {{ company.postAddress ? company.postAddress : '-' }}
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useRouter } from "vue-router";
import { useCompanyManagement } from "@/components/pages/settings/companyManagement/useCompanyManagement";
import { useUserAccess } from "@/use/userRoleAccess/useUserAccess";
import { Tabs } from "@/router/tabs";

import UiIcon from "@/components/ui/icon/UiIcon.vue";
import IconButton from "@/components/ui/button/IconButton.vue";
import SettingsPanel from "@/components/pages/settings/common/SettingsPanel.vue";
import UiIconNames from "@/components/ui/icon/UiIconNames";

export default defineComponent({
  name: "UserCompanyInfo",
  components: {
    IconButton,
    UiIcon,
    SettingsPanel,
  },
  setup() {

    const router = useRouter();
    const { isTmManager } = useUserAccess();

    const {
      isFetching,
      editableCompany: company,
      getMyCompany,
    } = useCompanyManagement();

    if (!isTmManager.value) getMyCompany();

    function goToPage() {
      router.push({ name: Tabs.Settings.CompanyManagement });
    }

    return {
      company,
      isFetching,
      goToPage,
      isTmManager,
      UiIconNames,
    }
  }
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/table/companyInfo';
</style>
